import {
  BLOCKS_CLIENT,
  BLOCKS_CLIENT_ETH,
  BLOCKS_CLIENT_X1,
  BLOCKS_CLIENT_RSS3,
  INFO_CLIENT,
  INFO_CLIENT_ETH,
  INFO_CLIENT_X1,
  INFO_CLIENT_RSS3,
} from 'config/constants/endpoints'
import { infoClientETH, infoClient, infoStableSwapClient, infoClientX1, infoClientRSS3 } from 'utils/graphql'

import { ChainId } from '@pancakeswap/sdk'
import {
  ETH_TOKEN_BLACKLIST,
  PCS_ETH_START,
  PCS_V2_START,
  TOKEN_BLACKLIST,
  BSC_TOKEN_WHITELIST,
  ETH_TOKEN_WHITELIST,
  X1_TESTNET_TOKEN_BLACKLIST,
  X1_TESTNET_TOKEN_WHITELIST,
  RSS3_START,
  RSS3_TOKEN_BLACKLIST,
  RSS3_TOKEN_WHITELIST,
} from 'config/constants/info'

export type MultiChainName = 'BSC' | 'ETH' | 'X1_TESTNET' | "RSS3"

export const multiChainQueryMainToken = {
  BSC: 'BNB',
  ETH: 'ETH',
  X1_TESTNET: 'OKB',
  RSS3: 'RSS3',
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT,
  ETH: BLOCKS_CLIENT_ETH,
  X1_TESTNET: BLOCKS_CLIENT_X1,
  RSS3:BLOCKS_CLIENT_RSS3,
}

export const multiChainStartTime = {
  BSC: PCS_V2_START,
  ETH: PCS_ETH_START,
  X1_TESTNET: 0,
  RSS3: RSS3_START,
}

export const multiChainId = {
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  X1_TESTNET: ChainId.X1_TESTNET,
  RSS3: ChainId.RSS3,
}

export const multiChainPaths = {
  [ChainId.BSC]: '',
  [ChainId.ETHEREUM]: '/eth',
  [ChainId.X1_TESTNET]: '/x1test',
  [ChainId.RSS3]: '/rss3',
}

export const multiChainQueryClient = {
  BSC: infoClient,
  ETH: infoClientETH,
  X1_TESTNET: infoClientX1,
  RSS3: infoClientRSS3,
}

export const multiChainQueryEndPoint = {
  BSC: INFO_CLIENT,
  ETH: INFO_CLIENT_ETH,
  X1_TESTNET: INFO_CLIENT_X1,
  RSS3: INFO_CLIENT_RSS3,
}

export const multiChainScan = {
  BSC: 'BscScan',
  ETH: 'EtherScan',
}

export const multiChainTokenBlackList = {
  BSC: TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  X1_TESTNET: X1_TESTNET_TOKEN_BLACKLIST,
  RSS3: RSS3_TOKEN_BLACKLIST,
}

export const multiChainTokenWhiteList = {
  BSC: BSC_TOKEN_WHITELIST,
  ETH: ETH_TOKEN_WHITELIST,
  X1_TESTNET: X1_TESTNET_TOKEN_WHITELIST,
  RSS3: RSS3_TOKEN_WHITELIST,
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
